<template>
    <div class="pipeline-content">
        <h4 class="empty-message mt-0" v-if="isEmpty">{{ $t("No Candidates Found") }}</h4>

        <component :is="htmlTag" :list="activeStageApplicants" class="row row-cols-xl-3 pipeline-emplyee-card" ghost-class="ghost" chosen-class="chosen" drag-class="drag" @end="sortCandidate" delay="100" :delay-on-touch-only="true">

            <div class="col default-card" v-for="applicant in activeStageApplicants" :draggable="draggableStatus" @dragstart="startCandidateDrag" @dragend="endCandidateDrag" :key="applicant.id" :applicant-id="applicant.id" :pipeline-id="applicant.pipeline.id">

                <div class="pipeline-card" @click.prevent="showCandidateDetails(applicant)">
                    <div class="user__image">
                        <img :src="applicant.image" @error="loadApplicantErrorImage(applicant)" alt="" class="w-100 img-fluid"/>
                    </div>
                    <div class="user__details">
                        <router-link :to="{name: 'applicant.details', params: {id:applicant.id } }"> <span class="user__name">{{ applicant.user.name }}</span></router-link>
                        <div class="d-flex">
                            <span class="user__address mr-1">{{ applicant.user.nationality }}</span>
                            <p class="user__experience">{{ applicant.total_experience }} Years</p>
                        </div>
                        <div class="d-md-flex justify-content-between mt-3">
                            <ai-score-progress-bar
                                    :is-pipeline="true"
                                    :sort-by="pageState.sortBy"
                                    :applicant="applicant"
                            />
                            <div class="application-duration">{{ applicant.updated_diff }}</div>
                        </div>
                        <div class="d-flex flex-wrap" style="z-index: -1;" @click.stop="showApplicantAssessment(applicant)" v-if="applicant.assessments.length>0">
                            <span class="label label-primary mr-1 mt-1" :class="applicant.assessments[0].class_name" :title="applicant.assessments[0].name">{{ applicant.assessments[0].name | shortText }}</span>
                            <span v-if="applicant.assessments.length>1" class="label label-primary mr-1 mt-1">+ {{ applicant.assessments.length - 1 }} more</span>
                        </div>
                    </div>

                    <div class="user__ratting info-button-light" :class="{'disabled' : applicant.rating === 0 }"><i class="eicon e-star"></i>
                        <template v-if="applicant.rating > 0">{{ applicant.rating }}</template>
                    </div>
                </div>

                <label v-if="canOrganizeCandidate" class="checkbox pipeline-checkbox" :for="`checkbox-${applicant.id}`">
                    <input type="checkbox" @change="selectApplicant(applicant)" v-model="applicant.isSelected" :id="`checkbox-${applicant.id}`">
                    <span></span>
                </label>
            </div>

        </component>
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import {UPDATE_CANDIDATE_PIPELINE_ORDER, UPDATE_PAGE_STATE} from '../../../constants/action-type';
import draggable from "vuedraggable";
import client from "../../../app/api/Client";
import {canOrganizeCandidate} from "../../../config/permission";

const AiScoreProgressBar = () => import("../../../components/jobs/AiScoreProgressBar");

export default {
    name: "JobCandidate",
    components: {
        AiScoreProgressBar,
        draggable
    },
    data() {
        return {
            htmlTag: 'ul',
            draggableStatus: false,
        }
    },
    computed: {
        ...mapState(['pageState']),
        isEmpty() {
            return this.activeStageApplicants?.length == 0;
        },
        activeStageApplicants() {
            return this.pageState?.activeStage?.applicants;
        },
        canOrganizeCandidate() {
            return canOrganizeCandidate();
        }
    },
    filters: {
        shortText: function (value) {
            if (!value) return '';
            if (value === undefined) return '';
            if (value.length > 15) {
                return value.slice(0, 15) + '...';
            }
            return value;
        }
    },
    methods: {
        ...mapActions([ UPDATE_PAGE_STATE , UPDATE_CANDIDATE_PIPELINE_ORDER]),

        startCandidateDrag(evt) {
            let elem = evt.target;

            setTimeout(() => {
                elem.style.opacity = 0;
            }, 0);

            this.UPDATE_PAGE_STATE({
                'dragApplicantId': parseInt(elem.getAttribute('applicant-id')),
                'dragPipelineId': this.pageState?.activeStage.id
            });
        },

        endCandidateDrag(evt) {
            let elem = evt.target;
            elem.style.opacity = 1;

            this.UPDATE_PAGE_STATE({'dragApplicantId': null, 'dragPipelineId': null});
            this.removeHighlightedBorder();
        },

        removeHighlightedBorder() {
            document.querySelectorAll('.dropable').forEach((el) => {
                el.classList.remove('dropable');
            });
        },

        selectApplicant(applicant) {

            let alreadySelectedIndex = this.pageState.selectedApplicant.indexOf(applicant.id);

            if (alreadySelectedIndex > -1) {
                this.pageState.selectedApplicant.splice(alreadySelectedIndex, 1);
            }
            if (applicant.isSelected) {
                this.pageState.selectedApplicant.push(applicant.id);
            }

            this.UPDATE_PAGE_STATE({'selectedApplicant': this.pageState.selectedApplicant});
        },

        showCandidateDetails(applicant) {
            if (event.ctrlKey || event.metaKey) {
                let routeData = this.$router.resolve({name: 'applicant.details', params: {id: applicant.id}});
                window.open(routeData.href, '_blank');
            } else {
                return this.$router.push({name: 'applicant.details', params: {id: applicant.id}});
            }
        },

        showApplicantAssessment(applicant) {
            return this.$router.push({name: 'applicant.details', params: {id: applicant.id}, query: {tab: 'evaluation-assessment'}});
        },

        async sortCandidate(evt) {

            if(this.pageState.dropCandidate && this.pageState.sortBy === '') {
                let applicants = this.activeStageApplicants.map(({id}, order) => {
                    return {
                        'applicant_id':id,
                        order
                    }
                });

                let {data: {data: candidatePipelineOrders}} = await client().post(`/job/${this.$route.params.jobId}/pipeline/candidate/re-order`, {
                    'applicants': applicants,
                });

                this.UPDATE_PAGE_STATE({'candidatePipelineOrders' : candidatePipelineOrders});

                this[UPDATE_CANDIDATE_PIPELINE_ORDER]();
            }

        }
    },
    created() {
        if(this.canOrganizeCandidate) {
            this.htmlTag = 'draggable';
            this.draggableStatus = false;
        }
    }
}
</script>

<style scoped>
    .application-duration {
        flex: 1 0 auto;
    }
    .user__details .d-flex {
        flex-wrap: wrap;
    }
    .user__details .user__experience {
        flex: 1 0 auto;
    }

    .default-card {
        padding: 0px;
        flex: 0 0 calc(33% - 30px) !important;
        margin: 0 12px 15px;
        background: transparent;
    }

    @media all and (max-width: 767px) {
        .default-card {
            flex: 0 0 calc(50% - 30px) !important;
        }

        .checkbox input[type="checkbox"]+span:before {
            font-size: 1.50rem;
        }

        .pipeline-checkbox {
            top: inherit !important;
            left: 15px;
            bottom: 15px;
        }
    }
    @media all and (max-width: 650px) {
        .default-card {
            flex: 0 0 calc(50% - 30px) !important;
        }
    }
    @media all and (max-width: 540px) {
        .default-card {
            flex: 0 0 calc(100% - 30px) !important;
        }
    }

    /* .chosen.ghost {
        opacity: 0 !important;
    } */

    .chosen {
        opacity: 0.5;
    }

    .chosen.ghost {
        opacity: 0;
    }

    .drag {
        opacity: 0.5;
    }

    .section-title {
        text-transform: unset;
    }

    .page-body .content-area__body .pipeline-section .pipeline-box .pipeline-content .pipeline-card .user__details .user__name {
        word-break: break-all;
    }

    .pipeline-card .user__ratting {
        position: absolute;
        top: 13px;
        right: 40px;
        font-size: .8em;
    }
    .pipeline-card .user__ratting i.eicon.e-star {
        font-size: .8em;
    }
    .pipeline-card {
        cursor: pointer;
    }
    .pipeline-card:hover {
        color: inherit;
        box-shadow: 0 5px 10px rgba(88, 125, 252, .3);
    }

    .page-body .content-area__body .pipeline-section .pipeline-box .pipeline-content .pipeline-card + .pipeline-checkbox {
        position: absolute;
        right: 5px;
        top: 15px;
        z-index: 5;
    }

    .label-primary {
        background: rgba(89, 125, 252, 0.15);
        color: #597dfc;
        font-size: 0.725rem;
        border-radius: 50px;
        padding: 5px 6px;
        line-height: 1;
        font-weight: 500;
        display: inline-block;
    }
    .ended {
        background: rgba(96, 206, 131, 0.15) !important;
        color: #60ce83;
    }
    .not-completed {
        color: #ff5f74;
        background: rgba(255, 95, 116, 0.15) !important;
    }

    .checkbox input[type="checkbox"]:checked+span:before {
        color: #60ce83;
    }

    @media all and (max-width: 991px) {
        .checkbox input[type="checkbox"]+span:before {
            font-size: 1.50rem;
            content: '\e90d' !important;
            color: #ddd;
            transition: color .3s ease;
        }
        .checkbox input[type="checkbox"]:checked+span:before {
            color: #60ce83;
        }
        .page-body .content-area__body .pipeline-section .pipeline-box .pipeline-content .pipeline-card + .pipeline-checkbox {
            top: auto !important;
            left: 28px;
            bottom: 15px;
            right: auto !important;
        }
        .pipeline-card .user__ratting {
            right: 15px;
        }
    }

    @media all and (max-width: 767px) {
        .application-duration {
            margin-top: 5px;
        }
    }

</style>
